<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card elevation="2">
    <v-card-text>
      <v-row justify="center">
        <template>
          <v-expansion-panels inset>
            <v-expansion-panel>
              <v-expansion-panel-header>Options</v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-sheet class="pa-5">
                    <v-row class="my-1">
                      <v-col>
                        <v-switch
                          v-model="useMerchant"
                          @change="changeUseMerchant"
                          inset
                          :label="'Use USAePay'"
                        ></v-switch>
                        <v-switch
                          v-model="notes_to_details"
                          @change="changeRequireNotes"
                          inset
                          :label="'Require notes to show Details'"
                        ></v-switch>
                        <v-switch
                          v-model="viewPayments"
                          @change="changeviewPayments"
                          inset
                          label="View Payments"
                        ></v-switch>
                        <v-switch
                          v-model="approveQ"
                          inset
                          label="Approve Quote"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="viewPPS"
                          @change="changeviewPPS"
                          inset
                          label="View PP_Sales"
                        ></v-switch>
                        <v-switch
                          v-model="selecDocuments"
                          @change="changeselecDocuments"
                          inset
                          label="Select Documents to Send"
                        ></v-switch>
                        <v-switch
                          v-model="viewpatientStatus"
                          @change="changeviewpatientStatus"
                          inset
                          label="View Patient Status"
                        ></v-switch>

                        <v-switch
                          v-model="sendQ"
                          inset
                          label="Send Quote"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="viewLabs"
                          @change="changeviewLabs"
                          inset
                          label="View Labs"
                        ></v-switch>
                        <v-switch
                          v-model="viewMHistory"
                          @change="changeviewMHistory"
                          inset
                          label="View Medical History"
                        ></v-switch>
                        <v-switch
                          v-model="soundNotification"
                          @change="changeSound"
                          inset
                          label="Sound Notification"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Tabs Patient Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <template>
                  <div class="d-flex justify-end">
                    <v-btn class="ma-2" color="warning" @click="resetTabs"
                      >Reset</v-btn
                    >
                  </div>
                </template>
                <template>
                  <Container
                    @drop="onDrop"
                    class="containerdrop"
                    :remove-on-drop-out="true"
                    drag-class="dragging"
                    orientation="horizontal"
                  >
                    <Draggable v-for="(tab, i) in tabPatientDetails" :key="i">
                      <v-card class="tabs text-center">
                        <div>
                          <v-icon>{{ tab.icon }}</v-icon>
                          <p>{{ tab.title }}</p>
                        </div>
                      </v-card>
                    </Draggable>
                  </Container>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Nomenclators</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-model="price"
                  @change="changeShowPrice"
                  label="Show Min and Max Prices"
                ></v-checkbox>
                <v-row>
                  <div class="col-sm-6">
                    <v-text-field
                      v-model="accessoryNom"
                      label="Nomenclator"
                      outlined
                      @change="changeNomenclators"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-6">
                    <v-btn
                      class="my-2"
                      @click="refreshNom()"
                      fab
                      small
                      color="primary"
                      ><v-icon dark> mdi-refresh </v-icon></v-btn
                    >
                  </div>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Seller"
                      v-model="sellerN"
                      @change="changeSellerN"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Coordinator"
                      @change="changeCoordinatorN"
                      v-model="coordinatorN"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Patient"
                      @change="changePatientN"
                      v-model="patientN"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="my-2"></v-divider>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--     <v-expansion-panel>
              <v-expansion-panel-header>Menu</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item v-for="(item, i) in dataCRM.menu" :key="i">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.showFor
                    }}</v-list-item-subtitle>
                    <v-btn
                      outlined
                      small
                      class="mx-1"
                      color="error"
                      dark
                      v-if="!item.hide && item.title != 'Configurations'"
                      @click="itemShowOrHide(item, true)"
                      >Hide</v-btn
                    >

                    <v-btn
                      outlined
                      small
                      v-if="item.hide && item.title != 'Configurations'"
                      color="success"
                      dark
                      @click="itemShowOrHide(item, false)"
                      >Show</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel> -->
            <v-expansion-panel>
              <v-expansion-panel-header>Colors</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-end mx-3 my-2">
                  <v-btn @click="refreshColors()" fab small color="primary"
                    ><v-icon dark> mdi-refresh </v-icon></v-btn
                  >
                </div>

                <v-list shaped>
                  <v-list-item-group color="primary">
                    <v-list-item>
                      <v-list-item-title>Primary</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('primary')"
                        :color="dataCRM.themes.light.primary"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Hprimary</v-list-item-title>
                      <v-btn
                        @click="showColorsDialog('hprimary')"
                        small
                        class="white--text"
                        :color="dataCRM.themes.light.hprimary"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Secondary</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('secondary')"
                        :color="dataCRM.themes.light.secondary"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Accent</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('accent')"
                        :color="dataCRM.themes.light.accent"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Error</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('error')"
                        :color="dataCRM.themes.light.error"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Info</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('info')"
                        :color="dataCRM.themes.light.info"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Success</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('success')"
                        :color="dataCRM.themes.light.success"
                        >color</v-btn
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Warning</v-list-item-title>
                      <v-btn
                        small
                        @click="showColorsDialog('warning')"
                        :color="dataCRM.themes.light.warning"
                        >color</v-btn
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <v-dialog v-model="dialogColor" persistent max-width="290">
          <v-card>
            <v-toolbar dark :color="oldColor">
              <v-toolbar-title>{{ key.toUpperCase() }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-color-picker v-model="color"></v-color-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="cancelDialog()" color="error">Cancel</v-btn>
              <v-btn text @click="changeColors()" color="success">Accept</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="editTypeItem.dialog"
          persistent
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title
              primary-title
              :class="
                editTypeItem.edit
                  ? 'blue darken-1 white--text'
                  : 'green darken-1 white--text'
              "
            >
              <template v-if="editTypeItem.edit">
                Edit Type: {{ editTypeItem.item.label }}
              </template>
              <template v-else> New Type </template>
            </v-card-title>
            <v-card-text class="mt-3">
              <v-text-field
                label="Label"
                v-model="editTypeItem.item.label"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="text-uppercase"
                label="Value"
                v-model="editTypeItem.item.value"
                outlined
                dense
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelall" color="error">Cancel</v-btn>
              <template v-if="editTypeItem.edit">
                <v-btn text @click="confirmEdit" color="info">Edit</v-btn>
              </template>
              <template v-else>
                <v-btn text @click="confirmAdd" color="success">Add</v-btn>
              </template>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="deleteItem.dialog"
          persistent
          :overlay="false"
          max-width="350px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="red darken-1 white--text" primary-title>
              Delete Type:
              {{ deleteItem.item != null ? deleteItem.item.label : "" }}
            </v-card-title>
            <v-card-text class="mt-3 text-center"
              ><h3>Are you sure?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelall" text>Cancel</v-btn>
              <v-btn @click="confirmDelete" text color="error">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end mr-2">
      <v-btn outlined @click="saveConfig()" color="primary">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  MUT_DATA_NOMPRICE,
  MUT_DATA_MENU,
  MUT_DATA_COLORS,
  MUT_DATA_NOMENCLATORS,
  MUT_METHODCALL,
  MUT_REQUIRENOTES,
  MUT_SELLERN,
  MUT_COORDINATORN,
  MUT_TYPEAPPOINT,
  MUT_VIEWPAYMENTS,
  MUT_PATIENTN,
  MUT_VIEWLABS,
  MUT_VIEWPPS,
  MUT_VIEWMHISTORY,
  MUT_SELECTDOCUMENT,
  MUT_APPROVEQ,
  MUT_VIEWPATIENTSTATUS,
  MUT_TAB_DETAILS,
  MUT_SOUND,
  MUT_USEMERCHANT,
  MUT_SENDQ,
} from "@/store/mutations/types";
import { mapActions, mapState, mapMutations } from "vuex";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  data() {
    return {
      price: false,
      color: "",
      oldColor: "",
      key: "primary",
      dialogColor: false,
      viewPayments: false,
      viewPPS: false,
      viewLabs: false,
      useMerchant: false,
      viewMHistory: false,
      soundNotification: false,
      approveQ: false,
      sendQ: false,
      viewpatientStatus: false,
      selecDocuments: false,
      accessoryNom: "",
      coordinatorN: "",
      sellerN: "",
      patientN: "",
      methodscall: "twilio",
      notes_to_details: false,
      appointmetsTypes: [],
      tabPatientDetails: [],
      editTypeItem: {
        dialog: false,
        item: { value: "", label: "" },
        index: -1,
        edit: false,
      },
      deleteItem: {
        dialog: false,
        item: null,
      },
    };
  },
  watch: {
    sendQ(val) {
      if (!val) {
        this.approveQ = true;
      }
      this.changesendQ();
    },
    approveQ(val) {
      if (!val) {
        this.sendQ = true;
      }
      this.changeapproveQ();
    },
  },
  mounted() {
    this.price = this.dataCRM.nomenclatorsPrice;
    this.sellerN = this.dataCRM.sellerN;
    this.patientN = this.dataCRM.patientN;
    this.coordinatorN = this.dataCRM.coordinatorN;
    this.viewPayments = this.dataCRM.viewPayments;
    this.viewPPS = this.dataCRM.viewPPS;
    this.viewLabs = this.dataCRM.viewLabs;
    this.viewMHistory = this.dataCRM.viewMHistory;
    this.soundNotification = this.dataCRM.soundNotification;
    this.useMerchant = this.dataCRM.useMerchant;
    this.approveQ = this.dataCRM.approveQ;
    this.sendQ = this.dataCRM.sendQ;
    this.viewpatientStatus = this.dataCRM.viewpatientStatus;
    this.selecDocuments = this.dataCRM.selecDocuments;
    this.methodscall = this.dataCRM.methodCall;
    this.notes_to_details = this.dataCRM.requireNotes;
    this.appointmetsTypes = this.dataCRM.appointmetsTypes;
    this.accessoryNom =
      this.dataCRM.nomenclators != undefined
        ? this.dataCRM.nomenclators.accessories
        : "Accessories";
    this.tabPatientDetails = this.dataCRM.tabPatientDetails;
  },
  computed: {
    ...mapState(["dataCRM", "configId"]),
  },

  methods: {
    ...mapMutations([
      MUT_DATA_NOMPRICE,
      MUT_DATA_MENU,
      MUT_DATA_COLORS,
      MUT_DATA_NOMENCLATORS,
      MUT_METHODCALL,
      MUT_REQUIRENOTES,
      MUT_SELLERN,
      MUT_COORDINATORN,
      MUT_TYPEAPPOINT,
      MUT_VIEWPAYMENTS,
      MUT_PATIENTN,
      MUT_VIEWPPS,
      MUT_VIEWLABS,
      MUT_VIEWMHISTORY,
      MUT_SELECTDOCUMENT,
      MUT_APPROVEQ,
      MUT_VIEWPATIENTSTATUS,
      MUT_TAB_DETAILS,
      MUT_SOUND,
      MUT_USEMERCHANT,
      MUT_SENDQ,
    ]),
    ...mapActions(["actAddSettings", "actUpdateSettings", "actRefreshColors"]),
    changeShowPrice() {
      this[MUT_DATA_NOMPRICE](this.price);
    },
    changeMethodCall() {
      this[MUT_METHODCALL](this.methodscall);
    },
    changeSellerN() {
      this[MUT_SELLERN](this.sellerN);
    },
    changePatientN() {
      this[MUT_PATIENTN](this.patientN);
    },
    changeCoordinatorN() {
      this[MUT_COORDINATORN](this.coordinatorN);
    },
    changeRequireNotes() {
      this[MUT_REQUIRENOTES](this.notes_to_details);
    },
    changeviewPayments() {
      this[MUT_VIEWPAYMENTS](this.viewPayments);
    },
    changeviewPPS() {
      this[MUT_VIEWPPS](this.viewPPS);
    },
    changeviewLabs() {
      this[MUT_VIEWLABS](this.viewLabs);
    },
    changeUseMerchant() {
      this[MUT_USEMERCHANT](this.useMerchant);
    },
    changeviewMHistory() {
      this[MUT_VIEWMHISTORY](this.viewMHistory);
    },
    changeSound() {
      this[MUT_SOUND](this.soundNotification);
    },
    changetabDetails() {
      this[MUT_TAB_DETAILS](this.tabPatientDetails);
    },
    changeapproveQ() {
      this[MUT_APPROVEQ](this.approveQ);
    },
    changesendQ() {
      this[MUT_SENDQ](this.sendQ);
    },
    changeviewpatientStatus() {
      this[MUT_VIEWPATIENTSTATUS](this.viewpatientStatus);
    },
    changeselecDocuments() {
      this[MUT_SELECTDOCUMENT](this.selecDocuments);
    },
    changeNomenclators() {
      this[MUT_DATA_NOMENCLATORS]({ accessories: this.accessoryNom });
    },
    changetypesappoint() {
      this[MUT_TYPEAPPOINT](this.appointmetsTypes);
    },
    itemShowOrHide(item, value) {
      const menus = this.dataCRM.menu;
      menus.map((men) => {
        if (men == item) {
          men.hide = value;
        }
      });
      this[MUT_DATA_MENU](menus);
    },
    showDialogType(item, index, edit) {
      this.editTypeItem = {
        dialog: true,
        item: item ? item : { label: "", value: "" },
        index: index ? index : -1,
        edit: edit,
      };
    },
    showDelete(item) {
      this.deleteItem = {
        dialog: true,
        item: item,
      };
    },
    cancelall() {
      this.editTypeItem = {
        dialog: false,
        item: { label: "", value: "" },
        index: -1,
        edit: false,
      };
      this.deleteItem = {
        dialog: false,
        item: null,
      };
    },
    confirmEdit() {
      this.editTypeItem.item.value = this.editTypeItem.item.value.toUpperCase();
      this.appointmetsTypes[this.editTypeItem.index] = this.editTypeItem.item;
      this.changetypesappoint(this.appointmetsTypes);
      this.cancelall();
    },
    confirmAdd() {
      this.editTypeItem.item.value = this.editTypeItem.item.value.toUpperCase();
      this.appointmetsTypes.push(this.editTypeItem.item);
      this.changetypesappoint(this.appointmetsTypes);
      this.cancelall();
    },
    confirmDelete() {
      const x = this.appointmetsTypes.filter(
        (typ) => typ.value != this.deleteItem.item.value
      );
      this.appointmetsTypes = x;
      this.changetypesappoint(this.appointmetsTypes);
      this.cancelall();
    },

    showColorsDialog(key) {
      this.key = key;
      this.dialogColor = true;
      switch (this.key) {
        case "hprimary":
          this.color = this.dataCRM.themes.light.hprimary;
          this.oldColor = this.dataCRM.themes.light.hprimary;
          break;
        case "secondary":
          this.color = this.dataCRM.themes.light.secondary;
          this.oldColor = this.dataCRM.themes.light.secondary;
          break;
        case "accent":
          this.color = this.dataCRM.themes.light.accent;
          this.oldColor = this.dataCRM.themes.light.accent;
          break;
        case "error":
          this.color = this.dataCRM.themes.light.error;
          this.oldColor = this.dataCRM.themes.light.error;
          break;
        case "info":
          this.color = this.dataCRM.themes.light.info;
          this.oldColor = this.dataCRM.themes.light.info;
          break;
        case "success":
          this.color = this.dataCRM.themes.light.success;
          this.oldColor = this.dataCRM.themes.light.success;
          break;
        case "warning":
          this.color = this.dataCRM.themes.light.warning;
          this.oldColor = this.dataCRM.themes.light.warning;
          break;
        default:
          this.color = this.dataCRM.themes.light.primary;
          this.oldColor = this.dataCRM.themes.light.primary;
          break;
      }
    },

    cancelDialog() {
      this.dialogColor = false;
      this.key = "primary";
    },
    refreshColors() {
      this.actRefreshColors();
    },
    refreshNom() {
      this.accessoryNom = "Accessories";
      this[MUT_DATA_NOMENCLATORS]({ accessories: this.accessoryNom });
    },

    async changeColors() {
      const thems = this.dataCRM.themes;
      switch (this.key) {
        case "hprimary":
          thems.light.hprimary = this.color;
          this.$vuetify.theme.themes.light.hprimary = this.color;

          break;
        case "secondary":
          thems.light.secondary = this.color;
          this.$vuetify.theme.themes.light.secondary = this.color;
          break;
        case "accent":
          thems.light.accent = this.color;
          this.$vuetify.theme.themes.light.accent = this.color;
          break;
        case "error":
          thems.light.error = this.color;
          this.$vuetify.theme.themes.light.error = this.color;
          break;
        case "info":
          thems.light.info = this.color;
          this.$vuetify.theme.themes.light.info = this.color;
          break;
        case "success":
          thems.light.success = this.color;
          this.$vuetify.theme.themes.light.success = this.color;
          break;
        case "warning":
          thems.light.warning = this.color;
          this.$vuetify.theme.themes.light.warning = this.color;
          break;
        default:
          thems.light.primary = this.color;
          this.$vuetify.theme.themes.light.primary = this.color;
          break;
      }
      await this[MUT_DATA_COLORS](thems);

      this.dialogColor = false;
      this.key = "primary";
    },

    resetTabs() {
      const thisTabs = [
        {
          title: "Check list",
          icon: "mdi-check-all",
          href: "#checklist",
        },

        {
          title: "Details",
          icon: "mdi-card-account-details",
          href: "#details",
        },
        {
          title: "Relationship",
          icon: "mdi-account-network",
          href: "#relations",
        },
        {
          title: "Medical History",
          icon: "mdi-clipboard-text",
          href: "#mhistory",
        },
        {
          title: "Labs",
          icon: "mdi-biohazard",
          href: "#labs",
        },
        {
          title: "Quotes",
          icon: "mdi-clipboard-text",
          href: "#quotes",
        },
        {
          title: "Accessories",
          icon: "mdi-cash-usd",
          href: "#accessories",
        },
        {
          title: "Payments",
          icon: "mdi-cash-usd",
          href: "#payments",
        },
        {
          title: "Notes",
          icon: "mdi-note-text",
          href: "#notes",
        },
        {
          title: "Document Signing",
          icon: "mdi-draw",
          href: "#documents",
        },
      ];

      this.tabPatientDetails = thisTabs;
      this.changetabDetails();
      this.saveConfig();
    },

    saveConfig() {
      const valor = JSON.stringify(this.dataCRM);
      this.$vuetify.theme.themes.light = this.dataCRM.themes.light;
      if (this.configId == "" || this.configId == undefined) {
        const settings = {
          name: "general_config",
          description: "All configurations ",
          value: valor,
          isPublic: true,
        };
        this.actAddSettings(settings).then(() => {
          notifySuccess("All Configurarions has been save");
        });
      } else {
        const settings = {
          name: "general_config",
          description: "All configurations ",
          value: valor,
          isPublic: true,
          uuid: this.configId,
          active: true,
        };
        this.actUpdateSettings(settings)
          .then(() => {
            notifySuccess("All Configurarions has been update");
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    /**Tabs */
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },

    onDrop(dropResult) {
      this.tabPatientDetails = this.applyDrag(
        this.tabPatientDetails,
        dropResult
      );
      setTimeout(() => {
        this.changetabDetails();
      }, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
.dragging {
  background-color: rgb(78, 153, 223);
  color: #ffffff;
}
.tabs {
  margin: 5px 5px !important;
  padding: 15px 0;
  cursor: pointer;
  height: 90px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  position: relative;
}
.containerdrop {
  display: flex !important ;
  flex-wrap: WRAP !important;
}
</style>
